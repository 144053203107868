import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {environment} from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  login(email: string, password: string): Observable<any> {
    const loginData = {email, password};
    return this.http.post<any>(`${environment.apiBaseUrl}/user/login`, loginData).pipe(
      tap(response => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 3);
        // this.cookieService.set('authToken', response.token, {expires: expirationDate});
        //this.cookieService.set('user', JSON.stringify(response.user), {expires: expirationDate});
        this.cookieService.set('authToken', response.token, {expires: expirationDate, path: '/', secure: true});
        this.cookieService.set('user', JSON.stringify(response.user), {
          expires: expirationDate,
          path: '/',
          secure: true,
        });

        console.log(response);
      })
    );
  }

  isLoggedIn(): boolean {
    return this.cookieService.check('authToken');
  }

  logout(): void {
    this.cookieService.delete('authToken');
    this.cookieService.delete('adminLanguage');
    
  }
}
